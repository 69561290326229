/* eslint-disable react/no-danger */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-floating-promises */

'use client'

import { useEffect } from 'react'
import Script from 'next/script'
import { initAckoAnalytics } from '@acko-tech/building-blocks.ui.acko-analytics'
import { useEnvVariablesClientConfig } from '@context/EnvClientContext/EnvVariablesClientContext'
import { TENANT_TYPE } from '@components/Ackodrive/common/constants'
import { getConfig } from '@configs/evnConfig'
import { getTrackerId, r2d2 } from '@utils/utils'
import { usePathname, useSearchParams } from 'next/navigation'
import { setUserVisit } from '@services/setUserVisit'
import { R2D2_EKIND_HOME } from '@constants/homeSegmentEvent'
// import useInitGlobals from '@scripts/gtmScripts'

export const InitialiseAckoAnalytics = ({ tenant = TENANT_TYPE.ACKOSEO, cdpApiEndPoint = '' }) => {
  const pathName = usePathname()
  const queryParams = useSearchParams()
  const config = useEnvVariablesClientConfig()
  const currentEnv = config?.ACKO_ENV

  useEffect(() => {
    if (tenant === TENANT_TYPE.HOMEPAGE && currentEnv) {
      ;(async () => {
        await setUserVisit(pathName, queryParams, currentEnv)
        await r2d2(
          {
            ekind: R2D2_EKIND_HOME,
            okind: 'tracker',
          },
          `${window?.location?.origin}/api/r2d2/`
        )
      })()
    }
  }, [currentEnv])

  const SEGMENT_WRITE_KEY = getConfig(currentEnv)?.segmentKey?.[tenant]

  const trackerIdVal = getTrackerId(tenant)

  return (
    <div>
      {trackerIdVal && currentEnv && SEGMENT_WRITE_KEY ? (
        <Script
          async
          id="my-script"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: initAckoAnalytics(trackerIdVal || '', SEGMENT_WRITE_KEY || '', cdpApiEndPoint || undefined),
          }}
        />
      ) : null}
    </div>
  )
}
