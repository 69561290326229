/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable func-names */
/* eslint-disable no-console */

'use client'

import { useEffect } from 'react'

let count = 0

const InjectGTM = ({ GTM_ID }) => {
  const gtm = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${GTM_ID}');`

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // If the script doesn't exist, inject it
      if (count === 0) {
        ++count
        const loadScript = (src = '', code = '') =>
          new Promise((resolve, reject) => {
            const script = document.createElement('script')
            script.id = 'gtm-script' // Add an ID to identify the script
            script.onload = () => resolve(script)
            script.onerror = () => reject(new Error('Error loading script.'))

            if (src) {
              script.src = src
              script.async = true
            } else if (code) {
              script.textContent = code
            } else {
              reject(new Error('Either a script URL or code string must be provided.'))
            }

            document.head.appendChild(script)
          })

        console.log('*****::::initGlobalsGTM::::*****')
        window.setTimeout(() => {
          loadScript('', gtm)
            .then(() => console.log('GTM Script Loaded Successfully'))
            .catch(err => console.log('GTM Script Error:', err))
        }, 500)
      } else {
        console.log('GTM script already injected')
      }
    }
  }, [GTM_ID])

  return null
}

export default InjectGTM
