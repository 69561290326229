import { COUPONS_LIST_COMPONENT } from '@components/HomePage/DownloadAppModal/constants'
import { HomeComponentType } from '@components/HomePage/utils/HomeComponentType'
import { WhatsappTemplate } from 'src/enums/WhatsappTemplate'

export const SEND_WHATSAPP_ENDPOINT = '/api/v1/whatsapp/send'
export const SEND_APP_LINK_FALLBACK_WHATSAPP_TEMPLATE = WhatsappTemplate.GenericAppDownload
export const ENTITY_SERVICE_ENDPOINT = '/api/user/details/'
export const HEADER_JSON_CONTENT = { 'Content-Type': 'application/json' }
export const HEADER_USER_ID_COOKIE = userId => ({
  Cookie: `user_id=${userId}`,
})

export const COMPONENT_WHATSAPP_TEMPLATE = {
  [HomeComponentType.RatingWidget]: WhatsappTemplate.GenericAppDownload,
  [HomeComponentType.DownloadAppBanner]: WhatsappTemplate.GenericAppDownload,
  create_abha: WhatsappTemplate.AbhaAppDownload,
  check_challans: WhatsappTemplate.ChallanAppDownload,
  add_vehicles: WhatsappTemplate.AssetsAppDownload,
  activate_safetynet: WhatsappTemplate.AmbulanceAppDownload,
  apply_visa: WhatsappTemplate.VisaAppDownload,
  [COUPONS_LIST_COMPONENT]: WhatsappTemplate.CouponsAppDownload,
}
