import { getConfig } from '@configs/evnConfig'

export const getLandingHeaders = (path, currentEnv) => {
  const baseUrl = getConfig(currentEnv)?.ackoBaseUrl
  const landingUrl = `${baseUrl}${path || ''}`
  const landingPath = path
  return {
    'x-landing-path': landingPath,
    'x-landing-url': landingUrl,
  }
}

export const getRequestBody = queryParams => {
  const reqBody = {
    gclid: queryParams?.get('gclid'),
    source: queryParams?.get('source'),
    utm_source: queryParams?.get('utm_source'),
    utm_medium: queryParams?.get('utm_medium'),
    utm_content: queryParams?.get('utm_content'),
    utm_campaign: queryParams?.get('utm_campaign'),
    campaign: queryParams?.get('campaign'),
    utm_term: queryParams?.get('utm_term'),
    category: queryParams?.get('category'),
  }
  return reqBody
}
