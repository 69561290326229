'use client'

import { createContext, type ReactNode, useContext, useState, useEffect } from 'react'
import { getRuntimeEnv } from '@components/env/extractEnvFromScript'

interface EnvVariablesClientConfig {
  ACKO_ENV: string | undefined
}

const defaultEnvVariables: EnvVariablesClientConfig = {
  ACKO_ENV: '',
}

const EnvVariablesClientContext = createContext<EnvVariablesClientConfig>(defaultEnvVariables)

interface EnvClientProviderProps {
  children: ReactNode
}

export const EnvVariablesClientProvider: React.FC<EnvClientProviderProps> = ({ children }) => {
  const [envs, setEnvs] = useState<EnvVariablesClientConfig>(defaultEnvVariables)

  useEffect(() => {
    const runtimeEnvs = getRuntimeEnv()
    setEnvs(runtimeEnvs)
  }, [])

  return <EnvVariablesClientContext.Provider value={envs}>{children}</EnvVariablesClientContext.Provider>
}

export const useEnvVariablesClientConfig = (): EnvVariablesClientConfig => {
  if (EnvVariablesClientContext === undefined) {
    throw new Error('useEnvVariablesClientConfig must be used within an EnvVariablesClientProvider')
  }

  return useContext(EnvVariablesClientContext)
}
