import { SET_VISIT_COOKIE } from '@constants/ackoVisit'
import { HEADER_JSON_CONTENT } from '@constants/communication'
import { POST_METHOD } from '@constants/httpVerbsConstants'
import { getLandingHeaders, getRequestBody } from '@factories/userVisit'

export const setUserVisit = async (pathName, queryParams, currentEnv) => {
  const landingHeaders = getLandingHeaders(pathName, currentEnv)
  const requestBody = getRequestBody(queryParams)
  try {
    const response = await fetch(SET_VISIT_COOKIE, {
      method: POST_METHOD,
      body: JSON.stringify(requestBody),
      headers: { ...HEADER_JSON_CONTENT, ...landingHeaders },
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
  } catch (err) {
    console.error(err, 'Error header visit api')
    return null
  }
}
