'use client'

import { createContext, type ReactNode, useContext, useState } from 'react'

interface UserContext {
  isLoggedIn: boolean
  setUserLoggedIn: (item: boolean) => void
}

const UserContextC = createContext<UserContext | undefined>(undefined)

interface UserProviderProps {
  children: ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [isLoggedIn, setUserLoggedIn] = useState<boolean>(false)

  const contextValue: UserContext = {
    isLoggedIn,
    setUserLoggedIn,
  }

  return <UserContextC.Provider value={contextValue}>{children}</UserContextC.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContextC)
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}
